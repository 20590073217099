import React from 'react'

import SEO from '../components/seo'

import Layout from '../components/layout'
import SectionHeader from '../components/SectionHeader/SectionHeader'
import OurClients from '../components/OurClients/OurClients'
import OurApproach from '../components/OurApproach/OurApproach'
import WeAre from '../components/WeAre/WeAre'
import Team from '../components/Team/Team'
import Member from '../components/Team/Member/Member'
import BottomNav from '../components/BottomNav/BottomNav'
import WorkWithUs from '../components/WorkWithUs/WorkWithUs'

const AboutPage = () => (
  <Layout>
    <SEO
      title="About"
      keywords={[
        `Emersoft`,
        `application`,
        `react`,
        `London`,
        `digital agency`,
      ]}
    />{' '}
    <SectionHeader>Behind every success stand right people </SectionHeader>{' '}
    <OurApproach
      title="Our Approach"
      paragraph1="One of the most challenging factors contributing to your success is choosing right people to work with."
      paragraph2="Unlike in big corporations our
  team is like a family. That’ s why everyone feels great here and is completely empowered to deliver the best possible outcome. In Emersoft, a strong work ethic and laid - back yet competitive atmosphere is at the forefront of values.
  "
    />{' '}
    <WeAre
      heading="Disciplined"
      img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/get-to-know-us-disciplined.jpg"
      sideText="We are"
    >
      We work hard but efficient. Each one of us takes full ownership of the
      project or task and does not settle until the result is first - class.{' '}
    </WeAre>{' '}
    <WeAre
      heading="Creative"
      isReverse="true"
      img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/get-to-know-us-creative.jpg"
      sideText="We are"
    >
      We work“ arm to arm” and brainstorm our ideas together. Repetition and
      routine are foreign words to us.{' '}
    </WeAre>{' '}
    <WeAre
      heading="Fired Up"
      img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/get-to-know-us-fired-up.jpg"
      sideText="We are"
    >
      We love what we do and each day is just another adventure for us. 9 - 5
      approach is not a part of our schedule.{' '}
    </WeAre>{' '}
    <Team title="Meet Some of Our Team Members">
      <Member
        img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/marcin-ruman-new.png"
        link="https://www.linkedin.com/in/marcinruman/"
        name="Marcin Ruman"
        authorRole="FOUNDER & CEO"
      />{' '}
      <Member
        img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/pawel-marciniuk-new.png"
        link="https://www.linkedin.com/in/pawel-marciniuk/"
        name="Pawel Marciniuk"
        authorRole="CTO / FRONT END DEVELOPER"
      />{' '}
      <Member
        img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/marcus-meazzo.png"
        link="https://www.linkedin.com/in/marcusmeazzo/"
        name="Marcus Meazzo"
        authorRole="BRAND ARCHITECT"
      />{' '}
      <Member
        img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/aidas.jpg"
        link="https://www.linkedin.com/in/aidas/"
        name="AIDAS ZUBKONIS"
        authorRole="SENIOR PHP DEVELOPER"
      />{' '}
      <Member
        img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/jonatan-plata.jpg"
        link="https://www.linkedin.com/in/jonatanplata/"
        name="JONATAN PLATA"
        authorRole="SENIOR FRONT END DEVELOPER"
      />{' '}
      <Member
        link="https://www.linkedin.com/in/marek-miotelka-1654b6b2/"
        img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/marek-miotelka.jpg"
        name="MAREK MIOTELKA"
        authorRole="SENIOR FRONT END DEVELOPER"
      />
      {/*<Member*/}
      {/*  link="/"*/}
      {/*  img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/emil-buszylo.jpeg"*/}
      {/*  name="EMIL BUSZYLO"*/}
      {/*  authorRole="SENIOR FULL STACK DEVELOPER"*/}
      {/*/>*/}
      <Member
        link="https://www.linkedin.com/in/sduxuidesigner/"
        img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/tori-gan.png"
        name="TORI GAN"
        authorRole="UX DESIGNER & UX WRITER"
      />
      <Member
        link="https://www.linkedin.com/in/bart%C5%82omiej-marczuk/"
        img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/bartek-marczuk.jpeg"
        name="BARTEK MARCZUK"
        authorRole="CUSTOMER SUCCESS / WEB DEVELOPER"
      />
      {/*<Member*/}
      {/*  img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/michal-kozak.jpg"*/}
      {/*  link="https://www.linkedin.com/in/michal-kozak-7a881013b/"*/}
      {/*  name="MICHAL KOZAK"*/}
      {/*  authorRole="JUNIOR WEB DEVELOPER"*/}
      {/*/>{' '}*/}
      {/*<Member*/}
      {/*  img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/malgo-mazur.jpg"*/}
      {/*  link="https://www.linkedin.com/in/ma%C5%82gorzata-mazur-66b90a207/"*/}
      {/*  name="MALGORZATA MAZUR"*/}
      {/*  authorRole="JUNIOR WEB DEVELOPER"*/}
      {/*/>{' '}*/}
      {/*<Member*/}
      {/*  img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/1613689549365 (2) (1).jpeg"*/}
      {/*  link="https://www.linkedin.com/in/szewczykrobert27/"*/}
      {/*  name="ROBERT SZEWCZYK"*/}
      {/*  authorRole="MARKETING SPECIALIST"*/}
      {/*/>{' '}*/}
      <Member
        img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/katarzyna-marciniuk.jpeg"
        link="https://www.linkedin.com/in/katarzyna-marciniuk-027b55157/"
        name="KASIA MARCINIUK"
        authorRole="FINANCIAL SPECIALIST"
      />{' '}
    </Team>
    <Team title="Emersoft Advisors">
      <Member
        img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/wojtek-zajac.jpeg"
        link="https://www.linkedin.com/in/wojciechzajac/"
        name="Wojciech Zajac"
        authorRole="TECH LEAD"
      />{' '}
      <Member
        img="https://cdn.emersoft.co/emersoft-website/get+to+know+us/grzegorz-kaliciak.png"
        link="https://www.linkedin.com/in/grzegorzkaliciak/"
        name="Grzegorz Kaliciak"
        authorRole="Chief Advisor & Digital Strategist"
      />{' '}
    </Team>
    <OurClients heading="Clients" borderTop /> <WorkWithUs> </WorkWithUs>{' '}
    <BottomNav
      firstLink="/our-work"
      firstHeading="Projects"
      firstSubHeading="See our work"
      secondLink="/services"
      secondHeading="Services"
      secondSubHeading="Learn more"
    />{' '}
  </Layout>
)

export default AboutPage
